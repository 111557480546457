module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-preact/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-V80J902R07"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Tidings Media","short_name":"tidingsmedia","start_url":"/","background_color":"#fff","theme_color":"#800000","display":"minimal-ui","icon":"src/assets/logo-light.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"31e88f2a7cb76ebe70c19129122c66ff"},
    },{
      plugin: require('../node_modules/gatsby-plugin-page-progress/gatsby-browser.js'),
      options: {"plugins":[],"includePaths":[{"regex":"^/blog"}],"excludePaths":["/blog/","/blog",{"regex":"/blog/[0-9]"},{"regex":"/blog/[0-9]/"}],"height":3,"prependToBody":false,"color":"var(--primary)","footerHeight":500},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]

// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-partners-tsx": () => import("./../../../src/pages/about/partners.tsx" /* webpackChunkName: "component---src-pages-about-partners-tsx" */),
  "component---src-pages-about-team-tsx": () => import("./../../../src/pages/about/team.tsx" /* webpackChunkName: "component---src-pages-about-team-tsx" */),
  "component---src-pages-about-work-tsx": () => import("./../../../src/pages/about/work.tsx" /* webpackChunkName: "component---src-pages-about-work-tsx" */),
  "component---src-pages-contact-us-tsx": () => import("./../../../src/pages/contact-us.tsx" /* webpackChunkName: "component---src-pages-contact-us-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-podcast-tsx": () => import("./../../../src/pages/podcast.tsx" /* webpackChunkName: "component---src-pages-podcast-tsx" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-work-with-us-tsx": () => import("./../../../src/pages/work-with-us.tsx" /* webpackChunkName: "component---src-pages-work-with-us-tsx" */),
  "component---src-templates-author-tsx": () => import("./../../../src/templates/author.tsx" /* webpackChunkName: "component---src-templates-author-tsx" */),
  "component---src-templates-blog-list-template-tsx": () => import("./../../../src/templates/blog_list_template.tsx" /* webpackChunkName: "component---src-templates-blog-list-template-tsx" */),
  "component---src-templates-blog-template-tsx": () => import("./../../../src/templates/blog_template.tsx" /* webpackChunkName: "component---src-templates-blog-template-tsx" */),
  "component---src-templates-category-tsx": () => import("./../../../src/templates/category.tsx" /* webpackChunkName: "component---src-templates-category-tsx" */),
  "component---src-templates-tag-tsx": () => import("./../../../src/templates/tag.tsx" /* webpackChunkName: "component---src-templates-tag-tsx" */)
}

